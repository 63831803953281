<template>
  <!-- <productBox1_skeletonLoader v-if="showSkeletonLoader" /> -->
  <div class="product-box" v-if="product">
    <div class="img-wrapper" :class="wrapperClasses">
      <!-- <nuxt-link draggable="false" :to="'/product/' + product.id" class="sliderBackground img-container"> -->
      <nuxt-link draggable="false" :to="`/product/${product.productHumanUrlName}`"
        class="sliderBackground img-container">
        <img v-if="product.image"
          :src="product.image.find(i => i.type == ProductImageType.IMAGE)?.resourceUrl || product.image[0].thumbnailUrl"
          class="img-fluid img img-responsive" draggable="false" alt="" />
      </nuxt-link>
      <div class="label-block p-0">
        <span class="label label-theme me-auto ms-0" v-if="product.customAttribute">
          {{ product.customAttribute }}
        </span>
        <span class="label label-theme" v-if="!product.variant && (product.productDiscountPecentage || 0) > 0">
          {{ product.productDiscountPecentage }}%
          {{ useRuntimeConfig().public.const.OFF }}
        </span>
        <span class="label label-theme" v-if="product.variant &&
    getMinPrice(product.variant).percentage ==
    getMaxPrice(product.variant).percentage
    && getMinPrice(product.variant).percentage > 0
    ">
          {{ getMinPrice(product.variant).percentage }}%
          {{ useRuntimeConfig().public.const.OFF }}
        </span>
        <span class="label label-theme" v-if="product.variant &&
    getMinPrice(product.variant).percentage !=
    getMaxPrice(product.variant).percentage
    && getMaxPrice(product.variant).percentage > 0
    ">
          {{ getMaxPrice(product.variant).percentage }}%
          {{ useRuntimeConfig().public.const.OFF }}
        </span>
      </div>
    </div>
    <div class="product-details text-center">
      <nuxt-link :to="`/product/${product.productHumanUrlName}`" class="font-default">
        <h5 class="fw-normal product-name">{{ product.productName }}</h5>
      </nuxt-link>
      <h3 class="theme-color fs-6 after-price" v-if="!product.variant">
        {{ $filter && $filter.formatCurrency(Big(product.productPriceAfterDiscount || 0).toNumber()) }}
        <br />
        <span class="font-light ml-1 normal-price" v-if="(product.productDiscountPecentage || 0) > 0">{{ $filter &&
    $filter.formatCurrency(Big(product.productPriceOriginal || 0).toNumber()) }}</span>
      </h3>
      <h3 class="theme-color fs-6 after-price" v-if="product.variant &&
    getMinPrice(product.variant).afterDiscount ==
    getMaxPrice(product.variant).afterDiscount
    ">
        {{ $filter && $filter.formatCurrency(Big(getMinPrice(product.variant).afterDiscount).toNumber()) }}
        <br />
        <span class="font-light ml-1 normal-price"
          v-if="Big(getMinPrice(product.variant).afterDiscount).cmp(Big(getMinPrice(product.variant).original)) != 0">{{
    $filter && $filter.formatCurrency(Big(getMinPrice(product.variant).original).toNumber()) }}</span>
      </h3>
      <h3 class="theme-color fs-6 after-price" v-if="product.variant &&
    getMinPrice(product.variant).afterDiscount !=
    getMaxPrice(product.variant).afterDiscount
    ">
        {{ $filter && $filter.formatCurrency(Big(getMinPrice(product.variant).afterDiscount).toNumber()) }}
        <br />
        <span class="font-light ml-1 normal-price"
          v-if="Big(getMinPrice(product.variant).afterDiscount).cmp(Big(getMinPrice(product.variant).original)) != 0">{{
    $filter && $filter.formatCurrency(Big(getMinPrice(product.variant).original).toNumber()) }}</span>
      </h3>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "nuxt/dist/app/compat/capi";
import VueFeather from "vue-feather";
import { ProductItem } from "~/contract/vo/ProductRelated.vo";
import { ProductVariantItem } from "~/contract/vo/ProductVariantRelated.vo";
import productBox1_skeletonLoader from "~/layout/elements/skeletonLoaders/productBox1_skeletonLoader.vue";
import { useCartStore } from "~/store/cart";
import { useWishlistStore } from "~/store/wishlist";
import Big from "big.js";
import { ProductImageType } from "~/contract/enum/ProductRelated.enum";

const props = defineProps({
  product: { type: Object as PropType<ProductItem> },
  wrapperClasses: { type: Object as PropType<any> },
});
const getImageUrl = (path: string) => {
  return "/images/" + path;
};

const toggleCartModal = (product: ProductItem) => { };
const toggleQuickViewModal = (product: ProductItem) => { };
// const addToWishlist = (product: ProductItem) => {
//   const productId = product.id;
//   const variantOptionId = null;
//   useWishlistStore().addToWishList(productId, variantOptionId);
// };

const addToCart = (product: ProductItem | undefined) => {
  if (product) {
    const productId = product.id;
    let variantOptionId = null;
    if (product.variant) {
      // variantOptionId = selectedVariantOption.value.variantOptionId;
      useRouter().push(`/product/${product?.productHumanUrlName}`);
    } else {
      useCartStore().addProductToCart({
        productId: productId,
        quantity: 1,
        variantOptionId: null
      });
    }
  }
};

const getMaxPrice = (productVariant: ProductVariantItem) => {
  return {
    afterDiscount: Math.max(
      ...productVariant.variantOption.map(
        (o) => o.productVariantPriceAfterDiscount || 0
      )
    ),
    original: Math.max(
      ...productVariant.variantOption.map(
        (o) => o.productVariantPriceOriginal || 0
      )
    ),
    percentage: Math.max(
      ...productVariant.variantOption.map(
        (o) => o.productVariantDiscountPecentage || 0
      )
    ),
  };
};

const getMinPrice = (productVariant: ProductVariantItem) => {
  return {
    afterDiscount: Math.min(
      ...productVariant.variantOption.map(
        (o) => o.productVariantPriceAfterDiscount || 0
      )
    ),
    original: Math.min(
      ...productVariant.variantOption.map(
        (o) => o.productVariantPriceOriginal || 0
      )
    ),
    percentage: Math.min(
      ...productVariant.variantOption.map(
        (o) => o.productVariantDiscountPecentage || 0
      )
    ),
  };
};
</script>

<style lang="scss" scoped>
.img-container {
  position: relative;
  overflow: hidden;
  padding-bottom: 100%;

  img {
    position: absolute;
    max-width: 100%;
    max-height: 100%;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.product-name{
  font-size: 0.9rem !important;
}

.after-price {
  font-size: 0.85rem !important;
}

.normal-price {
  font-size: 0.55rem !important;
}
</style>
